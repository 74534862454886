<template>
  <Modal @close="resetExpireReason" :visible="!!expireReason" :closable="true">
    <template v-slot:header>
      <h5 class="modal-title" id="loggedOutModalLabel">{{ $t('js.modal.logged_out.header') }}</h5>
    </template>
    <template v-slot:body>
      <div v-html="expireReason ? $t(`js.expire_reasons.${expireReason}`) : $t('js.modal.logged_out.body_html')"></div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import Modal from "./Modal.vue";

export default defineComponent({
  name: "LoggedOutModal",
  components: { Modal },
  computed: {
    ...mapState(useVotingSessionStore, ["expireReason"]),
  },
  methods: {
    ...mapActions(useVotingSessionStore, ["resetExpireReason"]),
  }
});
</script>
